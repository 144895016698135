<template>
    <front-layout>
        <v-container class="fill-height">
            <v-row class="text-center">
                <v-col cols="12">
                    <h2 class="text-uppercase">
                        {{this.$route.params.format}}
                    </h2>
                </v-col>
                <v-col cols="12">
                    <div>
                        <v-data-table
                            :headers="headers"
                            :items="formats"
                            class="elevation-1"
                            :hide-default-footer="true"
                            :items-per-page="-1"
                        >
                            <template v-slot:item.actions="{ item }">
                                <v-btn
                                    :to="item.route"
                                    class="white--text"
                                    color="primary"
                                    depressed
                                    x-small
                                >
                                    Convert
                                </v-btn>
                            </template>
                        </v-data-table>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </front-layout>
</template>

<script>
    import FrontLayout from "../../layouts/FrontLayout";
    import Formats from "../../api/Formats";
    export default {
        name: "Formats",
        components: {FrontLayout},
        data() {
            return {
                headers: [
                    { text: 'From', value: 'from' },
                    { text: 'To', value: 'to' },
                    { text: 'Actions', value: 'actions'},
                ],
                formats: []
            }
        },
        mounted() {
            this.fetchFormats()
        },
        methods: {
            async fetchFormats() {
                let formats = await Formats.supportedConvertFormats(this.$route.params.format);
                for (let key in formats) {
                    this.formats.push({ from: this.$route.params.format, to: formats[key], route: '' },)
                }
            }
        }
    }
</script>

<style scoped>

</style>
